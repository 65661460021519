body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}

.App {
  text-align: center;
  background-color: #282c34;
  width: 100%;
  height: 100%;
  position: fixed;
}

.App-header {
  color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 65px;
  display: flex;
}

.Conversation-area {
  border: 1px solid gray;
  border-radius: 5px;
  height: calc(87% - 89px);
  display: block;
  overflow: auto;
}

.Input-area {
  border: 1px solid azure;
  width: 80%;
  height: 32px;
  position: fixed;
  top: 90%;
  left: 10%;
}

input {
  resize: none;
  color: azure;
  background-color: #282c34;
  border: 0;
  width: 87%;
  height: 28px;
  font-size: 20px;
  position: absolute;
  top: 1px;
  left: 0;
}

textarea:focus, input:focus {
  outline: none;
}

.Submit-Button {
  background-color: azure;
  border: 0;
  width: 64.45px;
  height: 30px;
  position: absolute;
  top: 1px;
  right: 0;
}

.Clear-Button {
  background-color: azure;
  border: 0;
  width: 100px;
  height: 30px;
  position: absolute;
  top: 1px;
  right: -105px;
}

.user {
  float: right;
  text-align: right;
  color: azure;
  white-space: pre-wrap;
  width: 45%;
  margin-top: 0;
  margin-left: 45%;
  padding-right: 5%;
  position: relative;
}

.assistant {
  float: left;
  text-align: left;
  color: azure;
  white-space: pre-wrap;
  width: 45%;
  margin-top: 0;
  margin-right: 45%;
  padding-left: 5%;
  position: relative;
}

/*# sourceMappingURL=index.c038d8f2.css.map */
