.App {
  text-align: center;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #282c34;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 65px;
  color: white;
}

.Conversation-area {
  display:block;
  height:calc(87% - 89px);
  overflow: auto;
  border-width: 1px;
  border-style: solid;
  border-color: gray;
  border-radius: 5px;
}

.Input-area {
  position: fixed;
  top: 90%;
  left: 10%;
  width: 80%;
  height: 32px;
  border-width: 1px;
  border-style: solid;
  border-color: azure;
}

input {
  position: absolute;
  border: 0;
  left: 0;
  top: 1px;
  width: 87%;
  height: 28px;
  background-color: #282c34;
  resize: none;
  font-size: 20px;
  color:azure;
}

textarea:focus, input:focus {
  outline: none;
}

.Submit-Button {
  position:absolute;
  right: 0;
  top: 1px;
  width: 64.45px;
  border: 0;
  background-color: azure;
  height: 30px;
}

.Clear-Button{
  position: absolute;
  right: -105px;
  top: 1px;
  width: 100px;
  border: 0;
  background-color: azure;
  height: 30px;
}

.user {
  position: relative;
  padding-right: 5%;
  margin-top: 0;
  margin-left: 45%;
  float: right;
  width: 45%;
  text-align: right;
  color:azure;
  white-space: pre-wrap;
}

.assistant {
  position: relative;
  float: left;
  margin-top: 0;
  margin-right: 45%;
  padding-left: 5%;
  width: 45%;
  text-align: left;
  color:azure;
  white-space: pre-wrap;
}